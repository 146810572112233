import React from "react";
import { Select } from "@commonsku/styles";
import { createOptions } from "../../../../utils";
import { FilterMainComponentProps } from "../Filter";

const CompareToSelect = ({
  values,
  onChange,
  dependsOnValues = [],
  placeholder = "All",
}: FilterMainComponentProps) => {
  const intervalType = dependsOnValues[0] || "M";
  let rawOptions = [{ key: "previous", value: "Last Year" }];
  if ("M" === intervalType) {
    rawOptions = [
      {
        key: "previous",
        value: "Last Month",
      },
      {
        key: "period",
        value: "This Month Last year",
      },
    ];
  }
  if ("Q" === intervalType) {
    rawOptions = [
      {
        key: "previous",
        value: "Last Quarter",
      },
      {
        key: "period",
        value: "This Quarter Last year",
      },
    ];
  }

  const options = createOptions(rawOptions, "key", "value");

  return (
    <Select
      value={options.find((op) => op.value === values) || options[0]}
      onChange={(e) => onChange(e?.value || null)}
      isClearable={false}
      options={options}
    />
  );
};

export default CompareToSelect;
